import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card } from "antd"


const IndexPage = () => (
  <Layout hideActions>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Card
      title={"Hi, we're Mimecast!"}
      style={{
        fontFamily: "Open Sans",
        height: '100%',
        width: '100%',
        margin: 'auto',
        maxWidth: 600,
        borderRadius: '6px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)',
      }}
      headStyle={{
        fontFamily: "Open Sans",
        fontSize: '24px',
        fontWeight: '600px',
        color: '#333333',
        letterSpacing: '0',
        lineHeight: '28px',
        width: '100%',
        borderBottom: 0,
      }}
      bodyStyle={{ paddingTop: '0px', color: '#333333', }}>
        We use AI to reveal the hidden risks of email communications by inserting real-time warnings into emails.
        Mimecast makes it easy for you to identify and fight against phishing and impersonation.
        There's nothing for you to do for now. When you get a warning in the future you will land on this site.
      
    </Card>
  </Layout >
)

export default IndexPage
